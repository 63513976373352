import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';

export default function MarinaView() {
    const language = useSelector((state) => state.language.language);
    return (
        <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: { md: '100px', xs: '50px' }, width: { md: '100%', sm: '80%', xs: '100%' }, paddingBottom: { md: '100px', xs: '50px' }, paddingTop: { md: '100px', xs: '50px' } }}>

            <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, columnGap: { md: '50px', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, rowGap: { md: 'unset', xs: '50px' }, alignItems: 'center' }}>
                <Box sx={{ height: '490px', width: '100%' }}>
                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/Marine-Group 1299.png' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
                <Box sx={{ width: { md: '550px', xs: '90%' }, marginX: { md: 'unset', xs: 'auto' } }}>
                    <Typography variant="h6" component={'h1'} sx={{ fontSize: { md: '41px', xs: '30px' } }} fontWeight={'bold'}>{lng[language]['viewMarinaHeader']}</Typography>
                    <Typography variant="body1" fontSize={'15px'} fontWeight={'normal'} sx={{ whiteSpace: 'pre-line', marginTop: { md: '25px', xs: '25px' } }}>{lng[language]['viewMarinaDesc']}</Typography>
                </Box>
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, columnGap: { md: '50px', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, rowGap: { md: 'unset', xs: '50px' }, alignItems: 'center', justifyItems: 'center' }}>
                <Box sx={{ width: { md: '550px', xs: '90%' }, gridRowStart: { md: 1, xs: 2 }, gridRowEnd: { md: 2, xs: 3 }, marginX: { md: 'unset', xs: 'auto' } }}>
                    <Typography variant="h6" component={'h1'} fontWeight={'bold'} sx={{ fontSize: { md: '41px', xs: '30px' } }}>{lng[language]['publicBeachHeader']}</Typography>
                    <Typography variant="body1" fontSize={'15px'} fontWeight={'normal'} sx={{ whiteSpace: 'pre-line', marginTop: { md: '25px', xs: '25px' } }}>{lng[language]['publicBeachDesc']}</Typography>
                </Box>
                <Box sx={{ height: '490px', width: '100%' }}>
                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/Marine-Group 829.png' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, columnGap: { md: '50px', xs: 'unset' }, gridTemplateRows: { md: 'unset', xs: 'auto auto' }, rowGap: { md: 'unset', xs: '50px' }, alignItems: 'center' }}>
                <Box sx={{ height: '490px', width: '100%' }}>
                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/Marine-Group 1389.png' alt='' sx={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </Box>
                <Box sx={{ width: { md: '550px', xs: '90%' }, marginX: { md: 'unset', xs: 'auto' } }}>
                    <Typography variant="h6" component={'h1'} sx={{ fontSize: { md: '41px', xs: '30px' } }} fontWeight={'bold'}>{lng[language]['viewPromenadeHeader']}</Typography>
                    <Typography variant="body1" fontSize={'15px'} fontWeight={'normal'} sx={{ whiteSpace: 'pre-line', marginTop: { md: '25px', xs: '25px' } }}>{lng[language]['viewPromenadeDesc']}</Typography>
                </Box>
            </Box>

        </Box>
    )
}