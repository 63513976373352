import React from 'react';
import Widgets from './Widgets';
import Grid from '@mui/material/Grid2';
import { Link, useLocation } from 'react-router-dom';
const Columns = ({ column }) => {
    const location = useLocation();
    return (
        <Grid
            sx={column.style}
            size={column.props?.size ?? {lg: 6, md: 6, sm: 6, xs: 6}}
            {...(column.props.href && location.pathname !== '/widgets' ? { component: Link, to: `${column.props.href}` } : {})}
        >
            <Grid container>
                {column?.widgets?.map((widget) => (
                    <Widgets
                        key={widget.id}
                        widget={widget}
                        widgetStyle={widget?.style}
                        widgetProps={widget?.props}
                    />
                ))}
            </Grid>


        </Grid>
    );
};
export default Columns;