import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import EmailIcon from '@mui/icons-material/Email';
import ContactModal from '../../pages/public/ContactModal';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// import SimpleMortgageCalculator from './SimpleMortgageCalculator';
import { AnimatePresence, motion } from 'framer-motion';
import SimpleImageSlider from "react-simple-image-slider";
import { financingType } from '../../utils/utils';
import NormalCalculator from './NormalCalculator';
import { trackCustomEvent } from '../../utils/facebookPixel';

// import Fade from '@mui/material/Fade';
// import ClickAwayListener from 'react-click-away-listener';

const info = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #000',
    justifyContent: 'space-between',
}

export default function CardRezidencies({
    selected,
    imageName,
    buildingName,
    apartmentName,
    checkForMatchingId,
    handleCompare,
    objectName,
    // DATA
    id,
    floorNo,
    surfaceGrossWithTerrace,
    typology,
    surfaceTerrace,
    priceTotal,
    status,
    priceParking,
    orientation
}) {
    const images = [
        `https://fundway.com/images/2024/${orientation}1.webp`,
        `https://fundway.com/images/2024/${orientation}2.webp`,
        `https://fundway.com/images/2024/${orientation}3.webp`,
        `https://fundway.com/images/2024/${orientation}4.webp`,
        // `https://fundway.com/images/apartments/${imageName}`
    ]

    const language = useSelector((state) => state.language.language);
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    // const [price, setPrice] = useState(0);
    // const fullPrice = Math.ceil((priceTotal + priceParking) / 100) * 100;
    const [planDetailsModal, setPlanDetailsModal] = useState(false);
    const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
    const [openPayingWithBank, setOpenPayingWithBank] = useState(false);
    const [openCalculator, setOpenCalculator] = useState(false);

    // const [openCalls, setOpenCalls] = useState(false);

    // const handleTypologyClickAway = () => {
    //     if (openCalls) setOpenCalls(false)
    //     else return
    // };

    return (
        <Box sx={{ color: '#002856', display: 'grid', gridGap: '40px', gridTemplateColumns: { md: 'repeat(4, 1fr)', xs: '1fr' }, gridTemplateRows: { md: '1fr 1fr 1fr 50px', xs: 'auto auto auto auto' } }}>
            <Box sx={{ position: 'relative', gridRowEnd: { md: 1, xs: 2 }, gridRowStart: { md: 4, xs: 1 }, gridColumnEnd: { md: 3, xs: 2 }, gridColumnStart: { md: 1, xs: 1 } }}>
                <Box sx={{
                    height: { md: 'unset', xs: '400px' },
                    '& div > div > button:first-of-type': {
                        left: '0 !important',
                        width: '30px!important',
                        height: 30,
                        img: {
                            width: '30px !important',
                            height: 30,
                            background: '#00000038',
                        }
                    },
                    '& div > div > button:last-of-type': {
                        right: '0 !important',
                        width: '30px!important',
                        height: 30,
                        img: {
                            width: '30px !important',
                            height: 30,
                            background: '#00000038',
                        }
                    }
                }}>
                    <SimpleImageSlider
                        width={'100%'}
                        height={'100%'}
                        images={images}
                        showBullets={false}
                        showNavs={true}
                        loop
                        // autoPlayDelay={5.0}
                        // autoPlay
                        navStyle={1}
                    />
                </Box>
                <Box sx={{ display: { md: 'none', xs: 'flex', position: 'absolute', justifyContent: 'center', alignItems: 'center', border: 'solid 1px', backgroundColor: '#FFF', top: 30, left: 30 }, borderRadius: '10px' }}>
                    <Checkbox
                        onChange={(e) => handleCompare(e, id)}
                        checked={checkForMatchingId.includes(id) ? true : false}
                        defaultChecked={checkForMatchingId.length > 0 ? checkForMatchingId.includes(id) ? true : false : false}
                        disabled={checkForMatchingId.length === 2}
                    />
                    <Typography variant="subtitle1" sx={{ paddingRight: '10px' }}>{lng[language]['compare']}</Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    rowGap: '20px',
                    gridRowEnd: { md: 1, xs: 3 },
                    gridRowStart: { md: 4, xs: 2 },
                    gridColumnEnd: { md: 5, xs: 2 },
                    gridColumnStart: { md: 3, xs: 1 }
                }}
            >
                <Box>
                    <Typography variant="h1" textAlign={'left'} sx={{ fontSize: { sm: '30px', xs: '30px' }, fontWeight: selected === id ? '800 !important' : 'light' }}>{language === 'eng' ? apartmentName.replace('Apartamenti', 'Property') : apartmentName} {language === 'eng' ? buildingName.replace('Godina', 'Building') : buildingName} {objectName}</Typography>
                </Box>
                <Box sx={info}>
                    <Box className="containerLogoAndText">
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/stair.svg' />
                        <Typography variant="h6"  component={'p'}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                    </Box>
                    <Typography fontWeight={500}>+{floorNo}</Typography>
                </Box>

                <Box sx={info}>
                    <Box className="containerLogoAndText">
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/eye.svg' />
                        <Typography variant="h6" component={'p'}>{lng[language]['orientation'].toUpperCase()}</Typography>
                    </Box>
                    <Typography fontWeight={500}>{lng[language][orientation]}</Typography>
                </Box>

                <Box sx={info}>
                    <Box className="containerLogoAndText">
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' />
                        <Typography variant="h6" component={'p'}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                    </Box>
                    <Typography fontWeight={500}>{typology}</Typography>
                </Box>
                {
                    (surfaceTerrace === null || parseFloat(surfaceTerrace) === 0) ?
                        ''
                        :
                        <Box sx={info}>
                            <Box className="containerLogoAndText">
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/terrace.svg' />
                                <Typography variant="h6" component={'p'}>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                            </Box>
                            <Typography fontWeight={500}>{surfaceTerrace ? surfaceTerrace : 0} M²</Typography>
                        </Box>
                }
                <Box sx={info}>
                    <Box className="containerLogoAndText">
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' />
                        <Typography variant="h6" component={'p'}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                    </Box>
                    <Typography fontWeight={500}>{surfaceGrossWithTerrace} M²</Typography>
                </Box>

                {/* {
                    status === "free"
                    ?
                    <Box sx={info}>
                        <Box className="containerLogoAndText">
                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/money.svg' />
                            <Typography variant="h6">{lng[language]['priceTxt'].toUpperCase()}</Typography>
                        </Box>
                        <Typography fontWeight={500}>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(Math.ceil(fullPrice))}</Typography>
                    </Box>
                    :
                    
                } */}

                <Box sx={info}>
                    <Box className="containerLogoAndText">
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/parking.svg' sx={{ width: '25px !important' }} />
                        <Typography variant="h6" component={'p'}>PARKING</Typography>
                    </Box>
                    <Typography fontWeight={500}>
                        {
                            typology === 'DUPLEX' ?
                                lng[language]['parging2']
                                :
                                lng[language]['parking1']
                        }
                    </Typography>
                </Box>

            </Box>
            <Box sx={{ display: 'grid', gridGap: '10px', justifyContent: 'center', gridTemplateColumns: { md: '1fr 1fr 1fr', sm: '1fr 1fr 1fr', xs: '1fr 1fr' }, Button: { width: '100%', paddingY: '10px' }, gridRowEnd: { md: 4, xs: 4 }, gridRowStart: { md: 4, xs: 3 }, gridColumnEnd: { md: 3, xs: 2 }, gridColumnStart: 1, button: { height: { md: 'unset', xs: '50px' } }, a: { height: { md: 'unset', xs: '50px' } } }}>
                <Button sx={{ color: '#53626f', boxShadow: 'none', border: 'solid 1px #53626f', '&:hover': { color: '#FFF', backgroundColor: '#53626f' }, borderRadius: '0' }} onClick={() => navigate(`../residencieDetails/${id}`)}>{lng[language]['floorPlan'].toUpperCase()}</Button>
                {
                    status === 'free' ?
                        // <Button onClick={() => {
                        //     setPlanDetailsModal(true); 
                        //     fbq("track", "Payment Plan", {Apartment_id: id, Date: new Date()}); 
                        // }} sx={{ color: '#FFF', backgroundColor: '#53626f', border: 'solid 1px #53626f', borderRadius: '0', '&:hover': { color: '#53626f', backgroundColor: '#FFF' } }}>{lng[language]['paymentPlan'].toUpperCase()}</Button>
                        <Button onClick={() => {trackCustomEvent("ViewContent", {Apartment_id: id, Date: new Date()});  navigate(`/reserve/${id}`) }} sx={{ color: '#FFF', backgroundColor: '#53626f', boxShadow: 'none', border: 'solid 1px #53626f', borderRadius: '0', '&:hover': { color: '#53626f', backgroundColor: '#FFF' } }}>{lng[language]['takeOffer'].toUpperCase()}</Button>
                        :
                        // <Button disabled sx={{ color: '#bac0c5', border: 'solid 1px #bac0c5', '&:hover': { color: '#FFF', backgroundColor: '#53626f' }, borderRadius: '0' }}>{lng[language]['paymentPlan'].toUpperCase()}</Button>
                        <Button sx={{ backgroundColor: '#53626f', color: '#FFF', boxShadow: 'none', border: 'solid 1px #53626f', '&:hover': { color: '#53626f', backgroundColor: '#FFF' }, borderRadius: '0' }} onClick={() => { financingType('specialOffer'); navigate(`../reserve/${id}`); }}>
                            {lng[language]['takeOffer']}
                        </Button>

                }
                {/* <Button sx={{ color: '#FFF', backgroundColor: '#002856', border: 'solid 1px #002856', '&:hover': { color: '#002856', backgroundColor: '#FFF' }, borderRadius: '10px' }} onClick={() => navigate(`../reserve/${id}`)}>{lng[language]['offerPlan'].toUpperCase()}</Button> */}

                {/* 
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px', position: 'relative' }}>
                    <ClickAwayListener onClickAway={handleTypologyClickAway}>
                        <Box>
                            <Box sx={{ color: '#53626f', width: '100% !important', border: 'solid 1px #53626f', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', height: '100%', borderRadius: '5px' }} onClick={() => setOpenCalls(!openCalls)} >
                                <WhatsAppIcon sx={{ height: '30px', width: '30px' }} />
                            </Box>
                            <Fade in={openCalls} collapsedSize={40}>
                                <Box sx={{ position: 'absolute', backgroundColor: '#FFF', zIndex: 2, overflow: 'hidden', transition: '.3s all', top: '60px' }}>
                                    <Box component={'a'} href='tel:+38348180018' sx={{ border: 'solid 1px #53626F', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '30px', height: '30px' }}>
                                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/normal_call.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" color={'#53626F'} sx={{ marginLeft: '10px' }}>CALL</Typography>
                                    </Box>
                                    <Box component={'a'} href={`https://wa.me/${encodeURIComponent('+38348180018')}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '30px', height: '30px' }}>
                                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/whatsapp.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" color={'#53626F'} sx={{ marginLeft: '10px' }}>WHATSAPP</Typography>
                                    </Box>
                                    <Box component={'a'} href={`viber://contact?number=${encodeURIComponent(+38348180018)}`} sx={{ border: 'solid 1px #53626F', borderTop: 'none', display: 'flex', padding: '10px', alignItems: 'center', cursor: 'pointer' }} onClick={() => setOpenCalls(!openCalls)}>
                                        <Box sx={{ width: '30px', height: '30px' }}>
                                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/viber.svg' sx={{ width: '100%', height: '100%' }} />
                                        </Box>
                                        <Typography variant="subtitle1" color={'#53626F'} sx={{ marginLeft: '10px' }}>VIBER</Typography>
                                    </Box>
                                </Box>
                            </Fade>
                        </Box>
                    </ClickAwayListener> */}

                {/* <Button sx={{ color: '#53626f', width: '100% !important', border: 'solid 1px #53626f', borderRadius: '10px' }} onClick={() => setOpenModal(true)}><EmailIcon /></Button> */}
                {/* </Box> */}
                {
                    status === 'free' ?
                        <Button onClick={(e) => handleCompare(e, id)} sx={[{
                            boxShadow: 'none',
                            border: 'solid 1px #89939c', width: '157.33px', display: { md: 'flex', xs: 'none' }, padding: '5px 10px 5px 15px', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', borderRadius: '0',
                            backgroundColor: checkForMatchingId?.includes(id) ? '#012ae4' : '#FFF',
                            color: checkForMatchingId?.includes(id) ? '#fff' : '#53626f',
                            '&:hover': { background: '#012ae4', color: '#FFF' }
                        }, checkForMatchingId?.length > 0 ? checkForMatchingId?.includes(id) ? { background: '#012ae4', color: '#FFF' } : { background: '#FFF', color: '#53626f' } : { background: '#FFF', color: '#53626f' }]}>
                            {/* <Checkbox
                                onChange={(e) => handleCompare(e, id)}
                                defaultChecked={checkForMatchingId.length > 0 ? checkForMatchingId.includes(id) ? true : false : false}
                            // disabled={checkForMatchingId.length === 3}
                            /> */}
                            {lng[language]['compare']}
                        </Button>
                        :
                        <Button disabled sx={{ border: 'solid 1px #bac0c5', width: '157.33px', boxShadow: 'none', display: { md: 'flex', xs: 'none' }, padding: '5px 10px 5px 15px', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', borderRadius: '0' }}>
                            <Typography variant="subtitle1" color="#bac0c5" sx={{ fontSize: '14px', fontWeight: 500 }}>{lng[language]['compare']}</Typography>
                        </Button>
                }
            </Box>
            {
                openModal &&
                <Box sx={{ position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <ContactModal setState={setOpenModal} />
                </Box>
            }

            {
                planDetailsModal &&
                <>
                    {/* MOBILE */}
                    <Box sx={{ display: { md: 'none', xs: 'block' }, backgroundColor: '#FFF', position: 'fixed', top: 0, left: 0, height: '100%', width: '100%', zIndex: 999, overflow: 'scroll' }}>
                        <Box sx={{ gridTemplateRows: 'auto 1fr', display: 'grid' }}>
                            <Box sx={{ width: '90%', marginX: 'auto', display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
                                <CloseIcon onClick={() => setPlanDetailsModal(false)} sx={{ cursor: 'pointer' }} />
                            </Box>
                            <Box sx={{ width: '90%', marginX: 'auto' }}>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateRows: '80px 1fr'
                                    }}
                                >
                                    <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openSelfPayingPlan ? '#002856' : '#002856', }}
                                        onClick={() => setOpenSelfPayingPlan(!openSelfPayingPlan)}
                                    >
                                        <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayingPlan']}</Typography>
                                        <Box>
                                            <KeyboardArrowRightIcon />
                                        </Box>
                                    </Box>
                                    <AnimatePresence >
                                        {
                                            openSelfPayingPlan === true ?
                                                <Box
                                                    component={motion.section}
                                                    key="content"
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={{
                                                        open: { opacity: 1, height: "auto" },
                                                        collapsed: { opacity: 0, height: 0 },
                                                    }}
                                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    sx={{ marginTop: '20px' }}
                                                >

                                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                            <Typography>%</Typography>
                                                            {/* <Typography>&euro;</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * fullPrice)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * fullPrice)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                            <Typography>30%</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format((30 / 100) * fullPrice)}</Typography> */}
                                                        </Box>

                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                            <Typography>10%-5000&euro;</Typography>
                                                            {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR', maximumSignificantDigits: 10 }).format(((10 / 100) * fullPrice) - 5000)}</Typography> */}
                                                        </Box>
                                                        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px' }}>
                                                            <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                            {/* <Typography>120px</Typography> */}
                                                            <Typography>5000&euro;</Typography>
                                                        </Box>

                                                    </Box>
                                                    <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0,  boxShadow: 'none',backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button>
                                                </Box>
                                                :
                                                ''
                                        }
                                    </AnimatePresence>
                                </Box>

                               
                                <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                    <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openPayingWithBank ? '#002856' : '#002856', }} onClick={() => setOpenPayingWithBank(!openPayingWithBank)}>
                                        <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['selfPayWithBank']}</Typography>
                                        <Box><KeyboardArrowRightIcon /></Box>
                                    </Box>

                                    <AnimatePresence initial={false}>
                                        {
                                            openPayingWithBank === true ?
                                                <Box
                                                    component={motion.section}
                                                    key="content"
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={{
                                                        open: { opacity: 1, height: "auto" },
                                                        collapsed: { opacity: 0, height: 0 },
                                                    }}
                                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    sx={{ marginTop: '20px', paddingBottom: '70px' }}
                                                >
                                                    <Box>
                                                        <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>

                                                    </Box>

                                                    <Box>
                                                        <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0,  boxShadow: 'none',backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                            {lng[language]['takeOffer']}
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                :
                                                ''
                                        }
                                    </AnimatePresence>

                                </Box>

                                <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                                    <Box sx={{ marginTop: '20px', display: 'flex', borderBottom: 'solid 1px', paddingY: '20px', cursor: 'pointer', justifyContent: 'space-between', color: openCalculator ? '#002856' : '#002856', }} onClick={() => setOpenCalculator(!openCalculator)}>
                                        <Typography variant="h6" sx={{ fontSize: '18px' }}>{lng[language]['calculator']}</Typography>
                                        <Box><KeyboardArrowRightIcon /></Box>
                                    </Box>

                                    <AnimatePresence initial={false}>
                                        {
                                            openCalculator === true ?
                                                <Box
                                                    component={motion.section}
                                                    key="content"
                                                    initial="collapsed"
                                                    animate="open"
                                                    exit="collapsed"
                                                    variants={{
                                                        open: { opacity: 1, height: "auto" },
                                                        collapsed: { opacity: 0, height: 0 },
                                                    }}
                                                    transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                                                    sx={{ marginTop: '20px', paddingBottom: '20px' }}
                                                >
                                                    <Box>
                                                        <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginX: 'auto' }}>
                                                        <NormalCalculator />
                                                    </Box>
                                                    {/* <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                        {lng[language]['takeOffer']}
                                                    </Button> */}
                                                </Box>
                                                :
                                                ''
                                        }
                                    </AnimatePresence>

                                </Box>
                            </Box>
                        </Box>
                    </Box>



                    {/* DESKTOP */}
                    <Box sx={{ display: { md: 'flex', xs: 'none' }, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )', zIndex: 997, justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'grid', background: '#FFF', height: 'auto', paddingY: '20px', width: '1200px', gridTemplateColumns: '1fr 1fr', position: 'relative' }}>
                            <Box sx={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer' }} onClick={() => setPlanDetailsModal(false)}>
                                <CloseIcon />
                            </Box>
                            <Box sx={{ width: '100%', borderRight: '0.5px solid #c4c4c4' }}>
                                <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '60px 1fr', display: 'grid' }}>
                                    <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayingPlan']}</Typography>
                                    <Box>
                                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr', rowGap: '20px' }}>
                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal' }}>{lng[language]['installments']}</Typography>
                                                <Typography>%</Typography>
                                                {/* <Typography>&euro;</Typography> */}
                                            </Box>

                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px', textAlign: 'left' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader1']} {lng[language]['selfPayingPlanList1']}</Typography>
                                                <Typography>30%</Typography>
                                                {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format((30 / 100) * fullPrice)}</Typography> */}
                                            </Box>

                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader2']} {lng[language]['selfPayingPlanList2']}</Typography>
                                                <Typography>30%</Typography>
                                                {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format((30 / 100) * fullPrice)}</Typography> */}
                                            </Box>

                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader3']} {lng[language]['selfPayingPlanList3']}</Typography>
                                                <Typography>30%</Typography>
                                                {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format((30 / 100) * fullPrice)}</Typography> */}
                                            </Box>

                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px ', columnGap: '10px' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader4']} {lng[language]['selfPayingPlanList4']}</Typography>
                                                <Typography>10%-5000&euro;</Typography>
                                                {/* <Typography>{new Intl.NumberFormat('us-US', { style: 'currency', currency: 'EUR' }).format(((10 / 100) * fullPrice) - 5000)}</Typography> */}
                                            </Box>
                                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 80px', columnGap: '10px' }}>
                                                <Typography variant="h6" sx={{ fontSize: '15px', fontWeight: 'normal', maximumSignificantDigits: 10 }}>{lng[language]['selfPayingPlanListHeader5']} {lng[language]['selfPayingPlanList5']}</Typography>
                                                {/* <Typography></Typography> */}
                                                <Typography>5000&euro;</Typography>
                                            </Box>

                                        </Box>
                                        <Button onClick={() => { financingType('getOfferWithSelfFinancing'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0,  boxShadow: 'none',backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                            {lng[language]['takeOffer']}
                                        </Button>
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: '25px' }}>

                                    <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '100px 1fr', display: 'grid' }}>
                                        <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['selfPayWithBank']}</Typography>
                                        <Box>
                                            <Typography variant="body1" sx={{ fontSize: '15px', whiteSpace: 'pre-wrap' }}>{lng[language]['offerWithBank']}</Typography>
                                        </Box>
                                        <Box>
                                            <Button onClick={() => { financingType('getOfferWithBank'); navigate(`../reserve/${id}`); }} variant='contained' sx={{ borderRadius: 0, boxShadow: 'none', backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                                {lng[language]['takeOffer']}
                                            </Button>
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>

                            <Box sx={{ width: '100%', borderLeft: '0.5px solid #c4c4c4' }}>
                                <Box sx={{ width: '90%', marginX: 'auto', gridTemplateRows: '60px 1fr', display: 'grid' }}>
                                    <Typography variant="h5" fontSize={'28px'} fontWeight={'normal'} sx={{ width: '80%' }}>{lng[language]['calculator']}</Typography>
                                    <Box>
                                        <Box>
                                            <Typography variant="body1" sx={{marginBottom: 2}}>{lng[language]['calculateTitle']}</Typography>
                                        </Box>
                                        <Box>
                                            <NormalCalculator />
                                            {/* <SimpleMortgageCalculator price={fullPrice} /> */}
                                        </Box>
                                        {/* <Button onClick={() => {financingType('getOfferWithBank'); navigate(`../reserve/${id}`);}} variant='contained' sx={{ borderRadius: 0, backgroundColor: '#002856', color: '#FFF', border: 'solid 1px #002856', paddingY: 1.4, marginTop: '20px', '&:hover': { color: '#002856', backgroundColor: '#FFF' } }}>
                                            {lng[language]['takeOffer']}
                                        </Button> */}
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>



                </>
                // const [openSelfPayingPlan, setOpenSelfPayingPlan] = useState(false);
                // const [openCalculator, setOpenCalculator] = useState(false);
            }
        </Box>
    )
}