import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FetchData from '../../api/FetchData';
import Typography from '@mui/material/Typography';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import { Alert, Box, Button } from '@mui/material';
import Loader from '../../components/loader/Loader';
export default function ConfirmEmail() {
    
    const params = useParams();
    const navigate = useNavigate();
    const language = useSelector((state) => state.language.language);
    const [emojiShow, setEmojiShow] = useState(null);
    const [failed, setFailed] = useState(null);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useState({
        open: false,
        severity: '',
        msg: ''
    })
    const checkResult = () => {
        FetchData.create('/email/checkConfirmation', {
            code: params.id,
            lang: language
        })
        .then(res => {
            if (res?.data?.success === true) {
                setEmojiShow(<InsertEmoticonIcon sx={{fontSize: '100px', fill: 'rgb(56, 142, 60)'}} />);
                setFailed(false);
                returnToAps();
            }else if(res?.data?.success === false) {
                setEmojiShow(<SentimentVeryDissatisfiedIcon sx={{fontSize: '100px', fill: 'rgb(211, 47, 47)'}} />);
                setFailed(true);
            }
            setLoading(false);
        })
    }

    const returnToAps = () => {
        setTimeout(() => {
            navigate('../properties')
        }, 5000)
    }
 
    useEffect(() => {
        checkResult();
        // eslint-disable-next-line
    }, []);

    const resendEmailFund = () => {
        FetchData.create('/email/resendEmail', {
            lang: language,
            code: params.id
        })
        .then(res => {
            if (res.data.success) {
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: lng[language]['checkEmail']
                });
                handleClose();
            }

        })
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 5000)
    };

    return (
    <Box sx={{marginTop: '50px'}}>
        {
            loading === false ?
            (emojiShow) &&
            <Box sx={{width: {md: '1024px', xs: '90%'}, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginX: 'auto', paddingTop: '40px'}}>
                <Box sx={{height: '100px'}}>
                    {
                        failed ?
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/emailFail.svg' alt='' sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                        :
                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/confirmMail.svg' alt='' sx={{width: '100%', height: '100%', objectFit: 'cover'}} />
                    }
                </Box>
                <Typography textAlign={'center'} sx={{fontWeight: 'bold', fontSize: '25px', color: '#002856', marginTop: '40px', marginX: 'auto', width: {md: '500px', xs: '90%'}}}>
                    {
                        failed ?
                        lng[language]['confirmEmailFail']
                        :
                        lng[language]['confirmEmailSuccess']
                    }
                </Typography>
                {
                    failed &&
                    <Button sx={{marginTop: '40px', background: '#002856', border: 'solid 1px #002856',boxShadow: 'none', borderRadius: 0, color: '#FFF', '&:hover': {color: '#002856',background: '#FFF'}}}
                    onClick={() => resendEmailFund()}
                    >
                        {lng[language]['resendEmail']}
                    </Button>
                }           
            </Box>
            :
            <Loader state={loading} />
        }
        {
            alert.open &&
            <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{'.MuiAlert-action': {display: 'none'}, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%', borderRadius: 0}}>
                    {lng[language][alert.msg]}
                </Alert>
            </Box>
        }
    </Box>
  )
}