import axios from "axios";

const url = new URL(window.location.href);
const fullUrl = url.origin || "https://fundway.com";
// const fullUrl = url.origin || "https://test.fundway.com";

// const fullUrl = "https://fundway.com";

export const api = axios.create({
    baseURL: fullUrl + "/api",
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
});

// api.interceptors.response.use(null, (err) => {
//     if (err.response?.status === 401) {
//         window.location.href = "/cms";
//     } else if (err.response?.status === 404) {
//         window.location.href = "/notfound";
//     }
//     return Promise.reject(err);
// });