import Box from '@mui/material/Box';
import style from './Overall.module.css';
import Countries from './Countries.json';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { lng } from '../../utils/language';
import Select from '@mui/material/Select';
import FetchData from '../../api/FetchData';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader/Loader';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import CircularProgress from '@mui/material/CircularProgress';
import EmailAlert from '../../components/functions/EmailAlert';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getUserData, saveUserData } from '../../utils/utils';
import { trackCustomEvent } from '../../utils/facebookPixel';

const defaultAlert = {open: false,severity: '',msg: ''};
const inputStyle = { '.MuiInputBase-root': { borderRadius: 0 } }
const defaultLabel = { color: '#002856', fontSize: '14px', fontWeight: '700', marginBottom: '5px' };
const defaultErrors = {firstname: false,lastname: false,email: false,prefix: false,mobile: false,paymentPlan: false};
const info = { display: 'flex', alignItems: 'center', paddingBottom: '10px', borderBottom: 'solid 1px #000', justifyContent: 'space-between' };
const defaultUserData = {
    firstname: getUserData()?.firstname ? getUserData().firstname : '',
    lastname: getUserData()?.lastname ? getUserData().lastname : '',
    email: getUserData()?.email ? getUserData().email : '',
    prefix: getUserData()?.prefix ? getUserData().prefix : '',
    mobile: getUserData()?.mobile ? getUserData().mobile : '',
    nationality: '',
    nationalityId: '',
    residence: '',
    residenceId: '',
    paymentPlan: 'getOfferWithBank',
    subscribe: true
};

export default function RezidenciesOffert() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [apartment, setApartment] = useState(null);
    const [alert, setAlert] = useState(defaultAlert);
    const [buttonLoading, setButtonLoading] = useState(false);
    const language = useSelector((state) => state.language.language);
    // User Data & Errors
    const [userData, setUserData] = useState(defaultUserData);
    const [userErrors, setUserErrors] = useState(defaultErrors);
    // Inputs
    const inputs = {
        // Mandatory
        firstname: (e) => {let value = e.target.value;const regex = /^[A-Za-z]( ?[A-Za-z] ?)*$/g;if (value !== "" && !value.match(regex)) {setUserErrors({ ...userErrors, firstname: true });}else if (value.match(regex) || value !== "") {setUserErrors({ ...userErrors, firstname: false });}setUserData({ ...userData, firstname: value });},
        lastname: (e) => {let value = e.target.value;const regex = /^[A-Za-z]( ?[A-Za-z] ?)*$/g;if (value !== "" && !value.match(regex)) {setUserErrors({ ...userErrors, lastname: true });}else if (value.match(regex) || value !== "") {setUserErrors({ ...userErrors, lastname: false });}setUserData({ ...userData, lastname: value });},
        email: (e) => {let value = e.target.value;const regex = /^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/;if (value !== "" && !value.match(regex)) {setUserErrors({ ...userErrors, email: true });}else if (value.match(regex) || value !== "") {setUserErrors({ ...userErrors, email: false });}setUserData({ ...userData, email: value });},
        prefix: (e) => {var value = e.target.value;if (value !== "") {setUserErrors({ ...userErrors, prefix: false });}setUserData({ ...userData, prefix: value });},
        mobile: (e) => {var value = e.target.value;const regex = /^[0-9\s]*$/;if (value !== "" && !value.match(regex)) {setUserErrors({ ...userErrors, mobile: true });}else if (value.match(regex) || value !== "") {setUserErrors({ ...userErrors, mobile: false });}setUserData({ ...userData, mobile: value })},
        paymentPlan: (e) => {let value = e.target.value;setUserData({ ...userData, paymentPlan: value })},
        // Optional
        nationality: (e) => { var targetOption = e?.target?.options[e.target.selectedIndex]; let value = e?.target?.value; var countryname = targetOption.getAttribute('data-countryname'); setUserData({ ...userData, nationalityId: countryname, nationality: value }); },
        residence: (e) => { var targetOption = e?.target?.options[e.target.selectedIndex]; let value = e?.target?.value; var countryname = targetOption.getAttribute('data-countryname'); setUserData({ ...userData, residenceId: countryname, residence: value }); },
        subscribe: (e) => { let value = e.target.checked; setUserData({ ...userData, subscribe: value }) }
    }

    // Get apartment data
    const getApartment = () => {
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: id })
        .then(res => {setApartment(res?.data?.data[0]); setLoading(false);})
        .catch(err => { })
    }

    // Handle non optional inputs
    const valiedateData = () => {
        setUserErrors(prevErrors => ({...prevErrors,firstname: userData.firstname === '',lastname: userData.lastname === '',email: userData.email === '',mobile: userData.mobile === '',prefix: userData.prefix === '',paymentPlan: userData.paymentPlan === '' || userData.paymentPlan === null}));
        const errors = {firstname: userData.firstname === '',lastname: userData.lastname === '',email: userData.email === '',mobile: userData.mobile === '',prefix: userData.prefix === '',paymentPlan: userData.paymentPlan === '' || userData.paymentPlan === null}
        return errors;
    }

    // Handle Submit
    const handleSubmit = (event) => {
        event.preventDefault();
        const validation = Object.values(valiedateData());
        const confirmation = validation.includes(true);
        if (!confirmation) {
            setButtonLoading(true)
            FetchData.create('/email/confirmUserTest', {
                aptId: id,
                lang: language,
                userData: userData,
                typeOf: 'getOfferWithSelfFinancing',
                fileLocation: `../documents/${apartment.pdfFileName}`,
                building: `${language === 'eng' ? apartment.apartmentName.replace('Apartamenti', 'Property') : apartment.apartmentName} ${language === 'eng' ? apartment.buildingName.replace('Godina', 'Building') : apartment.buildingName} ${apartment.objectName}`
            })
            .then(res => {
                if (res?.data?.success) {
                    trackCustomEvent("Lead", {
                        type: 'Merr Çmimin',
                        Date: new Date(),
                        firstname: userData.firstname,
                        lastname: userData.lastname,
                        email: userData.email,
                        mobile: userData.mobile,
                        prefix: userData.prefix,
                    });
                    setAlert({open: true, severity: res?.data?.severity, msg: res?.data?.msg});
                    setUserData(defaultUserData);
                    setUserErrors(defaultErrors);
                    // if (res?.data?.userAlreadyLogged) {
                    //     deleteUser();
                    // }
                    if(res?.data?.notConfirmed) { 
                        handleRidirect(res.data.userToken);
                    }
                }
                //eslint-disable-next-line
                else if(res?.data?.userAlreadyLogged == false || res?.data?.notConfirmed){
                    saveUserData(
                        {
                            firstname: userData.firstname,
                            lastname: userData.lastname,
                            email: userData.email,
                            mobile: userData.mobile,
                            prefix: userData.prefix,
                            // otherCounty: formData.otherCounty,
                            // address: formData.address,
                            // countryName: formData.countryName,
                            // cityName: formData.cityName,
                            // countryId: formData.countryId,
                            // cityId: formData.cityId,
                            // postalCode: formData.postalCode,
                            // userId: formData.userId,
                        }
                    )
                    handleRidirect(res.data.userToken);
                }
                setButtonLoading(false);
            })
            .catch(err => {})

        } else setButtonLoading(false);
        
    }

    const handleRidirect = (data) => {
        setTimeout(() => {
            navigate(`../userData/?id=${data}&reserveId=${id}`);
        }, [3000])
    }

    useEffect(() => {
        getApartment();
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, [id])

    return (
        <Box sx={{ width: { md: '1024px', xs: '90%' }, marginX: 'auto', color: '#002856' }}>

            {
                loading === false ?
                    apartment &&
                    <>
                        {/* Apartment Name */}
                        <Box>
                            {
                                apartment.typology === 'DUPLEX' ?
                                    <Typography variant="h3" sx={{ fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>
                                        {apartment.apartmentId === '1650' ? language === 'alb' ? 'DUPLEX 1 Building 1 B2'.replace('Building', 'Godina') : 'DUPLEX 1 Building 1 B2' : ''}
                                        {apartment.apartmentId === '1651' ? language === 'alb' ? 'DUPLEX 2 Building 2 B2'.replace('Building', 'Godina') : 'DUPLEX 2 Building 2 B2' : ''}
                                        {apartment.apartmentId === '1652' ? language === 'alb' ? 'DUPLEX 3 Building 3 B2'.replace('Building', 'Godina') : 'DUPLEX 3 Building 3 B2' : ''}
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>{language === 'eng' ? apartment.apartmentName.replace('Apartamenti', 'Property') : apartment.apartmentName} {language === 'eng' ? apartment.buildingName.replace('Godina', 'Building') : apartment.buildingName} {apartment.objectName}</Typography>
                            }
                        </Box>
                        {/* List */}
                        <Box sx={{ width: { md: '1024px', sm: '100%', xs: '100%' }, rowGap: '10px', marginX: 'auto', display: 'grid', gridTemplateRows: 'auto auto auto auto auto auto', marginTop: '25px' }}>
                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>+{apartment.floorNo}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.typology}</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['orientation'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{lng[language][apartment.orientationName]}</Typography>
                            </Box>

                            {
                                apartment.surfaceTerrace &&
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/terrace.svg' />
                                        <Typography>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{apartment.surfaceTerrace ? apartment.surfaceTerrace : '0'} M²</Typography>
                                </Box>
                            }

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['apartmentSize'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceGrossWithoutTerrace} M²</Typography>
                            </Box>

                            <Box sx={info}>
                                <Box display={'flex'} alignItems={'center'} className="containerLogoAndText">
                                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '35px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                    <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                </Box>
                                <Typography fontWeight={500}>{apartment.surfaceGrossWithTerrace} M²</Typography>
                            </Box>

                            {
                                apartment.status === 'free'
                                    ?
                                    <Box sx={info}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '24px', xs: '24px !important' }, height: '100%', objectFit: 'contain' }} />
                                            <Typography marginLeft={'10px'} fontWeight={500} sx={{ fontSize: { md: '1rem', xs: '14px' } }}>PARKING</Typography>
                                        </Box>
                                        <Typography fontWeight={500}>{lng[language]['parking1']}</Typography>
                                    </Box>
                                    :
                                    ''
                            }

                        </Box>
                        {/* Form */}
                        <Box sx={{ marginTop: '25px' }}>
                        <Typography variant="h4" fontSize={21} fontWeight={'bold'}>{lng[language]['rezerveDetailFilterTitle']}</Typography>
                            {/* Inputs */}
                            <Box sx={{ display: 'grid', gridTemplateRows: { md: 'unset', xs: 'auto' }, gridTemplateColumns: { md: '1fr 1fr', xs: 'unset' }, rowGap: '20px', columnGap: { md: '20px', xs: 'unset' }, width: '100%', marginTop: '25px' }}>
                                {/* Firstname */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel sx={defaultLabel}>{lng[language]['rezerveDetailFilterName']}</InputLabel>
                                    <TextField fullWidth type='text' sx={inputStyle} value={userData.firstname} error={userErrors.firstname} onChange={(e) => inputs.firstname(e)} />
                                </div>
                                {/* Lastname */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel sx={defaultLabel}>{lng[language]['rezerveDetailFilterLastname']}</InputLabel>
                                    <TextField fullWidth type='text' sx={inputStyle} value={userData.lastname} error={userErrors.lastname} onChange={(e) => inputs.lastname(e)} />
                                </div>
                                {/* Email */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel sx={defaultLabel}>{lng[language]['rezerveDetailFilterEmail']}</InputLabel>
                                    <TextField fullWidth type='email' sx={inputStyle} value={userData.email} error={userErrors.email} onChange={(e) => inputs.email(e)} />
                                </div>
                                {/* Mobile */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterMob']}</InputLabel>
                                    <Box sx={{ display: 'grid', gridTemplateColumns: '120px 1fr', columnGap: '20px' }}>
                                        <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '120px' }}>
                                            <Select onChange={(e) => inputs.prefix(e)} error={userErrors.prefix} value={userData.prefix}>
                                                {Countries.map((data, index) => (<MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                        <Box><TextField type='tel' value={userData.mobile} fullWidth sx={inputStyle} error={userErrors.mobile} onChange={(e) => inputs.mobile(e)} /></Box>
                                    </Box>

                                </div>
                                {/* Nationality */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterCountry']} <Box component={'span'} color={'#707070'} fontSize={'10px'}>({lng[language]['inputOptional']})</Box></InputLabel>
                                    <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': { content: 'none' }, '::after': { content: 'none' }, border: 'solid 1px', '&:hover': { borderColor: '#000' } }, '.MuiNativeSelect-select': { padding: '15px !important' }, width: '100%' }]}>
                                        <NativeSelect onChange={(e) => inputs.nationality(e)} value={userData.nationality}>
                                            <option value="">{lng[language]['selectCountry']}</option>
                                            {Countries.map((data, index) => (<option key={index} data-countryname={Number(data.countryId)} value={data.country}>{data.country} {data.flag}</option>))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                {/* COUNTRY WHERE USER LIVES */}
                                <div className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['rezerveDetailFilterCountryOfResidece']} <Box component={'span'} color={'#707070'} fontSize={'10px'}>({lng[language]['inputOptional']})</Box></InputLabel>
                                    <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': { content: 'none' }, '::after': { content: 'none' }, border: 'solid 1px', '&:hover': { borderColor: '#000' } }, '.MuiNativeSelect-select': { padding: '15px !important' }, width: '100%' }]}>
                                        <NativeSelect onChange={(e) => inputs.residence(e)} value={userData.residence}>
                                            <option value="">{lng[language]['selectCountry']}</option>
                                            {Countries.map((data, index) => (<option key={index} data-countryname={Number(data.countryId)} value={data.country}>{data.country} {data.flag}</option>))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>

                                {/* COUNTRY WHERE USER LIVES
                                <div className={style.reserveApsInputs}>
                                    <InputLabel>{lng[language]['paymenyPlan']}</InputLabel>
                                    <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '100%' }}>
                                        <Select onChange={(e) => inputs.paymentPlan(e)} error={userErrors.paymentPlan} value={userData.paymentPlan}>
                                            <MenuItem value={"getOfferWithBank"}>{lng[language]['offerWithBank2']}</MenuItem>
                                            <MenuItem value={"getOfferWithSelfFinancing"}>{lng[language]['selfFinancing']}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div> */}

                            </Box>
                            {/* Checkbox */}
                            <Box sx={{ marginTop: '20px' }}>
                                <Box sx={{ '.MuiCheckbox-root': { border: 'solid 1px #002856', borderRadius: '1.5px', color: '#002856 !important' }, display: 'flex', alignItems: 'center' }}>
                                    <Checkbox disableRipple type='checkbox' sx={inputStyle} checked={userData.subscribe} onChange={(e) => inputs.subscribe(e)} />
                                    <Typography variant="subtitle1" color={'#002856'} fontSize={'11px'} marginLeft={'10px'} width={'239px'}>{lng[language]['abonohu']}</Typography>
                                </Box>
                                {language === 'alb' ? <Typography marginTop={'10px'} variant="subtitle1" color={'#707070'} fontSize={'13px'}>Ju lutemi vizitoni <Box component={Link} target='_blank' to="/pricaypolicy" color={'#707070'} sx={{ textDecoration: 'underline' }}>politikën e privatësisë</Box> për të kuptuar se si Fundway i trajton të dhënat tuaja personale.</Typography> : <Typography marginTop={'10px'} variant="subtitle1" color={'#707070'} fontSize={'13px'}>Please visit the <Box component={Link} target='_blank' to="/pricaypolicy" color={'#707070'} sx={{ textDecoration: 'underline' }}>privacy policy</Box> to understand how Fundway handles your personal data.</Typography>}
                            </Box>
                            {/* Submit Button */}
                            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <Button disabled={buttonLoading ? true : false} onClick={(e) => !buttonLoading ? handleSubmit(e) : null} sx={{ boxShadow: 'none',width: !buttonLoading ? '200px' : '200px', height: '50px', backgroundColor: buttonLoading ? '#002856 !important' : '#002856', color: buttonLoading ? '#FFF !important' : '#FFF', border: 'solid 1px #002856', borderRadius: 0, '&:hover': { backgroundColor: '#FFF', color: '#002856' } }}>
                                    {!buttonLoading ? lng[language]['takeOffer'] : <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#FFF' }} />}
                                </Button>
                            </Box>
                        </Box>
                        {
                            alert.open &&
                            <EmailAlert open={alert.open} severity={alert.severity} setOpen={setAlert} msg={lng[language][alert.msg]} />
                        }
                    </>
                    :
                    <Loader state={loading} />
            }

        </Box>
    )
}