import { Alert, Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { lng } from '../../utils/language'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import FetchData from '../../api/FetchData';
import Loader from '../../components/loader/Loader';

const inputStyle = {
    '.MuiInputBase-root': {
        width: { md: '100px', xs: '70px' },
    },
    '.MuiInputBase-input': {
        padding: 0,
        width: { md: '100px', xs: '70px' },
        height: { md: '100px', xs: '70px' },
        fontSize: '30px',
        textAlign: 'center',
    }
}

export default function ConfirmMobile() {
    const params = useParams();
    const language = useSelector((state) => state.language.language);
    const [alert, setAlert] = useState({
        open: false,
        severity: '',
        msg: ''
    })
    const [loading, setLoading] = useState(true);
    const [confirm, setConfirm] = useState({
        failed: null
    })

    const checkTimeLine = () => {
        FetchData.create('/email/checkConfirmationMobile', {
            type: 'checkCode',
            mobileCode: params.id,
            lang: language
        }).then(res => {
            setConfirm({
                failed: res.data.success   
            })
            setLoading(false)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        checkTimeLine();
        // eslint-disable-next-line
    }, [])

    const [confirmed, setConfirmed] = useState(null);
    const navigate = useNavigate();
    const [mobCode, setMobCode] = useState({
        first: '',
        second: '',
        third: '',
        fourth: '',
    })

    const inputRef1 = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputs = {
        firstNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setMobCode({
                    ...mobCode,
                    first: value
                });
                inputRef2.current.focus();
            }
            else return
        },
        secondNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setMobCode({
                    ...mobCode,
                    second: value
                });
                inputRef3.current.focus();
            }else return
            
        },
        thirdNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setMobCode({
                    ...mobCode,
                    third: value
                });
                inputRef4.current.focus();
            }else return
        },
        fourthNum: (e) => {
            let value = e.target.value;
            const regex = /^[0-9\b]+$/;
            if (value === '' || regex.test(value)) {
                setMobCode({
                    ...mobCode,
                    fourth: value
                });
                if (value !== '') {
                    if (mobCode.first !== '' && mobCode.second !== '' && mobCode.third !== '') {
                        hanleSubmit(`${mobCode.first}${mobCode.second}${mobCode.third}${value}`)
                    }else return
                }
            }else return
        },
    }

    const hanleSubmit = (code) => {
        FetchData.create('/email/checkConfirmationMobile', {
            type: 'checkConfirmationCode',
            code: code,
            mobileCode: params.id,
            lang: language
        })
        .then(res => {
            if (res?.data?.success === true) {
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: lng[language]['confirmationMobileComplete']
                });
                handleSuccess();
                handleClose()
            }else if(res?.data?.success === false){
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: lng[language]['notMatchingMobileCode']
                });
                handleFail();
                handleClose()
            }else{
                return
            }
        })
        .catch(err => {
        })
    }

    const handleSuccess = () => {
        setMobCode({
            first: '',
            second: '',
            third: '',
            fourth: '',
        })
        setConfirmed(true);
        setTimeout(() => {
            navigate('../properties')
        }, 6000)
    }

    const handleFail = () => {
        setMobCode({
            first: '',
            second: '',
            third: '',
            fourth: '',
        })        
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 6000)
    };

    const handleResend = () => {
        FetchData.create('/email/resendMobile', {
            code: params.id,
            lang: language
        })
        .then(res => {
            if (res.data.success) {
                setAlert({
                    open: true,
                    severity: res?.data?.severity,
                    msg: lng[language]['codeToMobile']
                });
                handleClose();
            }
            checkTimeLine();
        })

    }

    return (
        <Box paddingTop={'50px'}>
            {
                loading === false ?
                    <>
                        {
                            !confirm.failed ?
                            <Typography variant="h3" sx={{fontSize: '31px', color: '#002856', fontWeight: 'bold', textAlign: 'center', marginBottom: '30px', marginX: 'auto', width: {md: '90%', xs: '90%'}}}>
                                {
                                    language === 'alb' 
                                    ?
                                    'Kufiri prej 3 minutash ka skaduar.'
                                    :
                                    'The 3 minute limit has expired.'
                                }
                            </Typography>
                            :
                            <>
                                <Typography variant="h3" sx={{fontSize: '31px', color: !confirm.failed ? '#53626f' : '#002856', fontWeight: 'bold', textAlign: 'center', marginBottom: '30px', marginX: 'auto', width: {md: '500px', xs: '90%'}}}>{lng[language]['confirmMobileNum']}</Typography>

                                <Typography variant="h3" sx={{fontSize: '26px', color: !confirm.failed ? '#53626f' : '#002856', fontWeight: 500, textAlign: 'center', marginBottom: '30px'}}>{lng[language]['confirmMobileNumDesc']}</Typography>

                                <Typography variant="subtitle1" sx={{fontSize: '26px', color: !confirm.failed ? '#53626f' : '#002856', fontWeight: 500, textAlign: 'center', marginBottom: '30px'}}>Enter Code</Typography>
                            </>
                        }
                        <Box sx={{ width: { md: '1024px', xs: '90%' }, marginX: 'auto', display: 'grid', gridTemplateColumns: 'auto auto auto auto', alignItems: 'center', justifyItems: 'center', columnGap: { md: '30px', xs: '10px' }, opacity: !confirm.loading ? '0.6' : '1' }}>

                            {
                                confirmed === true ?
                                <>
                                    <TextField sx={inputStyle} value={mobCode.first} inputProps={{ maxLength: 1 }} inputRef={inputRef1} type='tel' disabled={true} />
                                    <TextField sx={inputStyle} value={mobCode.second} inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                    <TextField sx={inputStyle} value={mobCode.third}  inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                    <TextField sx={inputStyle} value={mobCode.fourth} inputProps={{ maxLength: 1 }} type='tel' disabled={true} />
                                </>
                                :
                                <>
                                    <TextField sx={inputStyle} value={mobCode.first} onChange={(e) => inputs.firstNum(e)} inputProps={{ maxLength: 1 }} autoFocus={true} inputRef={inputRef1} type='tel' disabled={!confirm.failed} />
                                    <TextField sx={inputStyle} value={mobCode.second} onChange={(e) => inputs.secondNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef2} type='tel' disabled={!confirm.failed} />
                                    <TextField sx={inputStyle} value={mobCode.third} onChange={(e) => inputs.thirdNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef3} type='tel' disabled={!confirm.failed} />
                                    <TextField sx={inputStyle} value={mobCode.fourth} onChange={(e) => inputs.fourthNum(e)} inputProps={{ maxLength: 1 }} inputRef={inputRef4} type='tel' disabled={!confirm.failed} />
                                </>
                            }
                        </Box>
                        {
                            !confirm.failed &&
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: '50px'}}>
                                <Button variant='contained' onClick={() => handleResend()} sx={{border: 'solid 1px #002856', borderRadius: 0,boxShadow: 'none', background: '#002856', color: '#FFF', '&:hover': {color: '#002856', backgroundColor: '#FFF'}}}>
                                    {lng[language]['resendCode']}
                                </Button>
                            </Box>
                        }
                    </>
                    :
                    <Loader state={loading} />
            }
            {
                alert.open &&
                <Box sx={{ width: '100%', bottom: '0', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center' }}>
                    <Alert onClose={handleClose} severity={alert.severity} variant="filled" sx={{'.MuiAlert-action': {display: 'none'}, display: 'flex', justifyContent: 'center', backgroundColor: '#002856', width: '100%', borderRadius: 0}}>
                        {lng[language][alert.msg]}
                    </Alert>
                </Box>
            }
        </Box>
    )
}