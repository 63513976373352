import React from 'react';
import Columns from './Columns';
import Grid from '@mui/material/Grid2';
const Rows = ({ row }) => {
    return (
        <Grid
            container
            spacing={row?.props?.spacing ?? { lg: 0, md: 0, sm: 0, xs: 0 }}
            direction={row?.props?.direction ?? 'row'}
            justifyContent={row?.props?.justifyContent ?? ''}
            sx={row?.style ?? {}}
        >
            {row.columns.map((column) => (
                <Columns
                    id={column.id}
                    key={column.id}
                    column={column}
                />
            ))}
        </Grid>
    );
};

export default Rows;