import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import ImageSlider from '../../components/slider/ImageSlider';





export default function Animities() {
    const language = useSelector((state) => state.language.language);
    const imageHeight = {
        height: {
            md: '500px',
            xs: '455px'
        },
        width: {
            sm: 'unset',
            xs: '100%'
        },
        marginX: 'auto',
        img: {
            width: '100%',
            height: '100%',
            objectFit: {
                md: 'cover',
                xs: 'cover'
            }
        }
    }

    const animitiesContainer = {
        marginY: '50px',
        display: 'grid',
        gridTemplateColumns: {
            sm: '1fr 1fr',
            xs: 'unset'
        },
        gridTemplateRows: {
            sm: 'unset',
            xs: 'auto auto'
        },
        columnGap: '20px',
        rowGap: {
            sm: 'unset',
            xs: '50px'
        },

        width: {
            md: '1024px',
            sm: '80%',
            xs: '100%'
        },
        marginX: 'auto'
    }

    const backgroundImage = "https://fundway.com/images/2024/MainPicture_landing.webp";

    const slider = [
        {
            imageName: 'https://fundway.com/images/2024/Slider_1.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_2.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_3.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_4.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_5.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_6.webp'
        },
        {
            imageName: 'https://fundway.com/images/2024/Slider_7.webp'
        }
    ];

    return (
        <Box sx={{ color: '#002856' }}>
            <Box
                sx={{
                    height: { md: '800px', sm: 'calc(98vh - 64px)', xs: 'calc(80vh - 56px)' },
                    display: 'grid',
                    gridTemplateRows: 'auto auto auto',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
            </Box>

            <Box sx={animitiesContainer}>
                <Box sx={{ width: '90%', marginX: 'auto', gridRowEnd: { sm: 'unset', xs: '2' }, gridRowStart: { sm: 'unset', xs: '1' } }}>
                    <Typography variant="h2" component={'h1'} sx={{ fontWeight: 'bold', fontSize: '41px', marginBottom: { sm: '0px', xs: '20px' } }}  >
                        {lng[language]['animitiesSubTitleText1']}
                    </Typography>
                    <Typography variant="subtitle1" component={'p'} sx={{ whiteSpace: 'preserve-breaks', fontWeight: 'normal', fontSize: '17px', marginTop: '30px' }} >
                        {lng[language]['animitiesSubDescText1']}
                    </Typography>
                </Box>
                <Box sx={[imageHeight, { marginTop: { md: '0px', xs: 0 } }]}>
                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/Jetese elegante.webp' />
                </Box>
            </Box>


            {/* PART 2 */}
            <Box>
                {/* HEADER */}
                {/* TITLE & DESC */}
                <Box sx={{ marginTop: '100px', marginX: 'auto', width: { md: '1024px', xs: '90%' }, display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: '1fr' } }}>
                    <Box sx={{ display: { md: 'block', xs: 'none' } }}></Box>
                    <Box>
                        <Typography variant="h4" component={'h1'} sx={{ fontSize: { md: '41px', xs: '30px' }, fontWeight: 'bold' }}>
                            {lng[language]['animitiesSubTitleText2']}
                        </Typography>

                        <Typography sx={{ whiteSpace: 'preserve-breaks', fontWeight: 'normal', fontSize: '17px', marginTop: '30px' }} >
                            {lng[language]['animitiesSubDescText2']}
                        </Typography>
                    </Box>

                </Box>

                {/* SLIDER */}
                <Box sx={{ marginTop: {md: '100px', xs: '50px'}, width: { md: '70%', xs: '100%' }, marginLeft: 'auto', height: { md: '530px', xs: '430px' }, overflow: 'hidden' }}>
                    
                <Box sx={{display: {md: 'none', xs: 'block'}}}>
                    <ImageSlider
                        images={slider}
                        settings={{
                            dots: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            cssEase: "linear",
                            }}
                        />
                    </Box>

                    <Box sx={{display: {md: 'block', xs: 'none'}}}>
                    <ImageSlider
                        images={slider}
                        settings={{
                            dots: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1.3,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                            cssEase: "linear",
                            }}
                        />
                    </Box>
                    
                    
                </Box>
            </Box>

            {/* PART 3 */}
            <Box sx={{marginTop: {md: '100px', xs: '50px'},display: 'grid',gridTemplateColumns: { md: '1.2fr 0.8fr', xs: '1fr' }, columnGap: {md: '50px', xs: 'unset'}}}>

                <Box sx={{height: {md: '500px',xs: '430px'}, display: {md: 'flex', xs: 'none'}}}>
                    <Box component={'img'}  loading="lazy" src={'https://fundway.com/images/2024/The Promenade.webp'} alt='' sx={{width: '100%',height: '100%',objectFit: 'cover'}}/>
                </Box>
                <Box sx={{ width: {md: '650px', xs:'90%'}, margin: 'auto' }}>
                    <Typography variant="h2" component={'h1'} sx={{ fontWeight: 'bold', fontSize: '41px', marginBottom: { sm: '0px', xs: '20px' }}}>
                        {lng[language]['animitiesSubTitleText4']}
                    </Typography>

                    <Typography sx={{ whiteSpace: 'preserve-breaks', fontWeight: 'normal', fontSize: '17px', marginTop: '30px' }} >
                       {lng[language]['animitiesSubDescText4']}
                    </Typography>
                </Box>

            </Box>

            {/* PART 4 */}
            <Box sx={{width: '100%',marginX: 'auto',marginTop: {md: '100px', xs: '50px'},display: 'grid'}}>
                <Box sx={{width: { md: '650px', xs: '90%' },marginX: 'auto',gridRowStart: { sm: 'unset', xs: '2' }}}>
                    <Typography variant="h2" component={'h1'} sx={{ fontWeight: 'bold', fontSize: '41px', marginBottom: { sm: '0px', xs: '20px' }, marginTop: {md: 'unset', xs: '50px'}}}>
                        {lng[language]['animitiesSubTitleText3']}
                    </Typography>

                    <Typography sx={{ whiteSpace: 'preserve-breaks', fontWeight: 'normal', fontSize: '17px', marginTop: '30px' }} >
                        {lng[language]['animitiesSubDescText3']}
                    </Typography>
                </Box>
                <Box sx={{width: { md: '50%', xs: '100%' },marginRight: { md: 'unset', xs: 'auto' },marginLeft: 'auto',height: {md: '500px',xs: '430px'},marginTop: {md: '100px', xs: '50px'}, gridRowStart: {md: 'unset', xs: 1}}}>
                <Box component={'img'}  loading="lazy" src={'https://fundway.com/images/2024/Marina.webp'} alt='' sx={{width: '100%',height: '100%',objectFit: 'cover'}} />
            </Box>

            </Box>

            {/* PART 5 */}
            <Box sx={{width: {md: '1024px', xs: '100%' }, marginX: 'auto', display: 'grid', gridTemplateColumns: {md: '1fr 1fr', xs: '1fr'}, marginTop: '100px'}}>
                <Box sx={{ width: '90%', marginX: 'auto', gridRowEnd: { sm: 'unset', xs: '2' }, gridRowStart: { sm: 'unset', xs: '2' }, marginTop: {md: 'unset', xs: '50px'} }}>
                    <Typography variant="h2" component={'h1'} sx={{ fontWeight: 'bold', fontSize: '41px', marginBottom: { sm: '0px', xs: '20px' }}}>
                        {lng[language]['animitiesSubTitleText5']}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ whiteSpace: 'preserve-breaks', fontWeight: 'normal', fontSize: '17px', marginTop: '30px' }} >
                        {lng[language]['animitiesSubDescText5']}
                    </Typography>
                </Box>
                <Box sx={[imageHeight, { marginTop: { md: '0px', xs: 0 }, gridRowStart: {md: 'unset', xs: 1}, }]}>
                    <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/Plazhet private.webp' />
                </Box>
            </Box>

        </Box>
    )
}

