import { createSlice } from '@reduxjs/toolkit'
import { isLocalStorageAccessible } from '../../utils/utils';

const initialState = {
    language: "eng",
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        checkLanguage: (state) => {
            if (isLocalStorageAccessible()) {
                const language = localStorage.getItem("lng");
                if (language) {
                    state.language = language
                }else{
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    const albaniaTimezones = ["Europe/Tirane", "Europe/Belgrade"];  // Adjust as needed
                    if (albaniaTimezones?.includes(timezone)) {
                        localStorage.setItem('lng', 'alb')
                        state.language = 'alb'
                    } else {
                        localStorage.setItem('lng', 'eng')
                        state.language = 'eng'
                    }
                }    
            }else{
                return 
            }
        },
        setLanguage: (state, action) => {
            if (action.payload) {
                state.language = action.payload
                localStorage.setItem('lng', state.language)
            }
        }
    },
})

export const { checkLanguage, setLanguage } = languageSlice.actions

export default languageSlice.reducer