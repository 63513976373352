import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import FetchData from '../../api/FetchData';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { lng } from '../../utils/language';
import { useSelector } from 'react-redux';
import AvaLoader from './AvailabilityLoader.json';
import SkeletonComp from '../../components/SkeletonComp';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import ModalComp from '../../components/modal/ModalComp';
import CloseIcon from '@mui/icons-material/Close';
import { getUserData, saveUserData, selectedItem } from '../../utils/utils';
import Loader from '../../components/loader/Loader';
import { trackCustomEvent } from '../../utils/facebookPixel';
import { FormControl, InputLabel, MenuItem, NativeSelect, Select, TextField } from '@mui/material';
import Countries from '../public/Countries.json'
const info = {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '10px',
    borderBottom: 'solid 1px #000',
    justifyContent: 'space-between',
    color: '#002856'
}

const inputStyle = {
    backgroundColor: '#FFF',
    '.MuiInputBase-root': {
        borderColor: '#FFF',
        color: '#000'
    },
    fieldset: {
        borderRadius: 0,
        borderWidth: '1px',
        borderColor: '#e0e0e0',
        color: '#000',
    }
}

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2
        }
    }
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

const defaultAlert = {
    open: false,
    severity: '',
    msg: ''
}

const defaultForm = {
    firstname: getUserData()?.firstname ? getUserData().firstname : '',
    lastname: getUserData()?.lastname ? getUserData().lastname : '',
    email: getUserData()?.email ? getUserData().email : '',
    tel: getUserData()?.tel ? getUserData().tel : '',
    prefix: getUserData()?.prefix ? getUserData().prefix : '',
    countryName: getUserData()?.countryName ? getUserData().countryName : '',
    countryId: getUserData()?.countryId ? getUserData().countryId : '',
}

const defaultErrorForm = {
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    telError: false,
    prefixError: false,
    countryError: false,
}

export default function Availability() {
    // const conesntGiven = useSelector((state) => state.consent.isConsentGiven);
    const stickyRef = useRef(null);
    //eslinst-disable-next-line
    const [
        isSticky,
        // eslint-disable-next-line
        setIsSticky
    ] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 899;

    const navigate = useNavigate()

    useEffect(() => {
        // const handleScroll = () => {
        //     if (stickyRef.current) {
        //         const stickyTop = stickyRef.current.getBoundingClientRect().top;
        //         // Check if the top of the sticky element has reached the top of the viewport
        //         if (stickyTop <= 0) {
        //             setIsSticky(true);
        //         } else {
        //             setIsSticky(false);
        //         }
        //     }
        // };

        // window.addEventListener('scroll', handleScroll);

        // // Cleanup listener on component unmount
        // return () => {
        //     window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    const [data, setData] = useState();
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [visibleAps, setVisibleAps] = useState();
    const [otherLoader, setOtherLoader] = useState(false);
    const language = useSelector((state) => state.language.language);
    const [dataSet, setDataSet] = useState({ apartments: null, loading: true });

    useEffect(() => {
        FetchData.create('/apartments/getApartments', { orderBy: 'floorNo' })
        .then(res => {
            window.scrollTo(0, 0);
            if (res?.data?.success) {
                setData(res?.data?.data.apartment);
                setVisibleAps(res?.data?.data.apartment.splice(0, 15));
                setCount(res?.data?.data.count[0]);
            }
            setDataSet({ ...dataSet, loading: false });
        })
        .catch(err => {
            console.log(err);
        })
        // eslint-disable-next-line
    }, []);

    const loadMoreData = () => {
        setOtherLoader(true);
        const newVisibleCount = parseInt(page) + parseInt(15);
        setPage(newVisibleCount);
        setVisibleAps(prevApartments => [
            ...prevApartments,
            ...data.slice(page, newVisibleCount),
        ]);
        setOtherLoader(false);
    }

    const [image, setImage] = useState('Default');
    const [imageModal, setImageModal] = useState(false);
    const [name, setName] = useState('');
    const [orientation, setOrientation] = useState('');
    const [imageLoading, setImageLoading] = useState(true);
    const [id, setId] = useState();
    const handleImageModal = (appId, name, orientation, id) => {
        setImage(appId);
        setImageModal(true);
        setName(name);
        setOrientation(orientation);
        setId(id)
    }

    const freeState = () => {
        setImage('Default');
        setName('')
        setOrientation('')
        setImageModal(false);
    }

    const handleRidirect = (data, id) => {
        selectedItem(id);
        navigate(`../properties`);
    }
    const [showNewAps, setNewShowAps] = useState(false);
    const [newApartment, setNewApartment] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleNewApsShow = (id) => {
        setLoading(true);
        FetchData.create('/apartments/getSpecificApartemnt', { apartmentId: id })
        .then(res => {
            setNewApartment(res?.data?.data[0]);
        })
        .finally(() => {
            setLoading(false);
            setNewShowAps(true);
        })
    }

    const [openModal, setOpenModal] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [alert, setAlert] = useState(defaultAlert);

    const [formData, setFormData] = useState(defaultForm);
    const [formDataErrors, setFormDataErrors] = useState(defaultErrorForm);

    const inputFunc = {
        firstnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, firstnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, firstnameError: false }) } setFormData({ ...formData, firstname: value }) },
        lastnameInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) { setFormDataErrors({ ...formDataErrors, lastnameError: true }) } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value !== "") { setFormDataErrors({ ...formDataErrors, lastnameError: false }) } setFormData({ ...formData, lastname: value }) },
        emailInput: (e) => { var value = e.target.value; if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) { setFormDataErrors({ ...formDataErrors, emailError: true }) } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") { setFormDataErrors({ ...formDataErrors, emailError: false }) } setFormData({ ...formData, email: value }) },
        prefixInput: (e) => { var value = e.target.value; if (value !== "") { setFormDataErrors({ ...formDataErrors, prefixError: false }) } setFormData({ ...formData, prefix: value }); },
        telInput: (e) => { var value = e.target.value; const regex = /^[0-9\s]*$/; if (value !== "" && !value.match(regex)) { setFormDataErrors({ ...formDataErrors, telError: true }) } else if (value.match(regex) || value !== "") { setFormDataErrors({ ...formDataErrors, telError: false }) } setFormData({ ...formData, tel: value }) },
        countryInput: (e) => {
            var targetOption = e.target.options[e.target.selectedIndex];
            var value = e?.target?.value;
            var countryname = targetOption.getAttribute('data-countryname');
            if (value !== "") {
                setFormDataErrors({
                    ...formDataErrors,
                    countryError: false
                })
            };
            setFormData({
                ...formData,
                countryId: value,
                countryName: countryname,
            });
        },
    }

    const valiedateData = () => {
        setFormDataErrors(prevErrors => ({
            ...prevErrors,
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            countryError: formData.countryName === '' || formData.countryName === null,
        }));

        const errors = {
            firstnameError: formData.firstname === '',
            lastnameError: formData.lastname === '',
            emailError: formData.email === '',
            telError: formData.tel === '',
            prefixError: formData.prefix === '',
            countryError: formData.countryName === '' || formData.countryName === null,
        }
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(valiedateData());
        const confirmation = validation.includes(true);
        if (!confirmation) {
            setLoadingButton(true)
            FetchData.create('/email/confirmUser', {
                userData: formData,
                lang: language,
                typeOf: 'downloadPlan',
                aptId: id.id,
                fileLocation: `../documents/${newApartment.pdfFileName}`,
                building: `${language === 'eng' ? newApartment.apartmentName.replace('Apartamenti', 'Property') : newApartment.apartmentName} ${language === 'eng' ? newApartment.buildingName.replace('Godina', 'Building') : newApartment.buildingName} ${newApartment.objectName}`
            })
                .then(res => {
                    if (res?.data?.success) {
                        trackCustomEvent('ViewContent', {
                            Apartment_id: newApartment.apartmentId,
                            type: 'Download Plan',
                            Date: new Date(),
                            firstname: formData.firstname,
                            lastname: formData.lastname,
                            email: formData.email,
                            tel: formData.tel,
                            prefix: formData.prefix,
                            otherCounty: formData.otherCounty,
                            country: formData.countryName,
                        });

                        handleClose();
                        setAlert({
                            open: true,
                            severity: res?.data?.severity,
                            msg: res?.data?.msg
                        });
                        if (res?.data?.userAlreadyLogged) {
                            setFormData(defaultForm);
                        }
                        if (res?.data?.userAlreadyLogged === false) {
                            saveUserData(
                                {
                                    firstname: formData.firstname,
                                    lastname: formData.lastname,
                                    email: formData.email,
                                    tel: formData.tel,
                                    prefix: formData.prefix,
                                    otherCounty: formData.otherCounty,
                                    country: formData.country,
                                }
                            )
                            handleRidirect(res.data.useToken);
                        }
                        if (res?.data?.notConfirmed === true) {
                            saveUserData(
                                {
                                    firstname: formData.firstname,
                                    lastname: formData.lastname,
                                    email: formData.email,
                                    tel: formData.tel,
                                    prefix: formData.prefix,
                                    otherCounty: formData.otherCounty,
                                    country: formData.country,
                                }
                            )
                            handleRidirect(res.data.useToken);
                        }
                    }
                    setLoadingButton(false);
                    setOpenModal(false);
                })
                .catch(err => {
                })

        } else return;
    }

    const handleClose = (event, reason) => {
        setTimeout(() => {
            setAlert({ ...alert, open: false });
        }, 5000)
    };

    useEffect(() => {
        if (showNewAps) {document.body.style.overflow = 'hidden';} else {document.body.style.overflow = 'auto';}
    
        return () => {document.body.style.overflow = 'auto';};
      }, [showNewAps]);

    return (
        <Box sx={{ width: { md: '80%', xs: '100%' }, marginX: 'auto', display: 'grid', gridTemplateColumns: { md: '0.3fr 0.7fr', xs: '1fr' }, position: 'relative', columnGap: '20px' }}>
            <Box sx={{ position: 'relative', width: '100%', marginTop: '80px', display: { md: 'block', xs: 'none' } }}>
                {
                    (image && !imageLoading)
                        ?
                        <Box sx={{ position: 'sticky', top: isSticky ? 1 / 2 : 0, left: 0, width: '100%', height: 400 }}>
                            <Box component={'img'}  loading="lazy" onLoad={() => setImageLoading(false)} src={`https://fundway.com/images/2024/${image}.png`} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            {(image !== 'Default' && !imageLoading) &&
                                <Box sx={{ color: '#11294D' }}>
                                    <Typography variant="subtitle1" component={'p'} sx={{ fontSize: '18px' }}>{name}</Typography>
                                    <Box component={'span'} sx={{ width: '90%', marginX: '0', height: '1px', backgroundColor: '#d4d4d4', display: 'block', marginY: '10px' }} />
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '90%', marginRight: 'auto' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '30px', xs: '20px' } }} />
                                            <Typography variant="subtitle1" component={'p'} marginLeft={'10px'} sx={{ fontSize: '18px' }}>{lng[language]['orientation']}</Typography>
                                        </Box>
                                        <Typography variant="subtitle1" component={'h4'} sx={{ fontSize: '18px' }}>{orientation}</Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        :
                        <Box sx={{ position: 'sticky', top: isSticky ? 1 / 2 : 0, left: 0, width: '100%', height: 400 }}>
                            <Box component={'img'}  loading="lazy" onLoad={() => setImageLoading(false)} src={`https://fundway.com/images/2024/Default.png`} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </Box>
                }

            </Box>

            <Box sx={{ width: '100%' }}>
                <Box ref={stickyRef} sx={{ position: isSticky ? 'sticky' : 'relative', top: 0, backgroundColor: '#fff' }}>
                    <Box sx={{ display: 'grid', marginX: 'auto', gridTemplateRows: '1fr', marginTop: { md: '50px', xs: '30px' } }}>
                        <Box component={motion.ul} className="container" variants={container} initial="hidden" animate="visible" sx={{ padding: 0 }}>

                            <Box component={motion.li} className="item" variants={item} sx={{ display: { md: 'none', xs: 'grid' }, columnGap: '10px', rowGap: { sm: '30px', xs: '15px' }, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', justifyItems: 'center' }}>
                                {/* <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} /> */}
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/terrace.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '25px', xs: '12.5px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/ballot-check.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/architect.svg' sx={{ width: { sm: '30px', xs: '15px' }, height: '100%', objectFit: 'contain' }} />
                            </Box>

                            <Box sx={{ display: { md: 'grid', xs: 'none' }, columnGap: '10px', rowGap: { sm: '30px', xs: '15px' }, gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr', justifyItems: 'center', marginTop: '10px' }}>
                                {/* <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#11294D'} variant="body1">{lng[language]['floor'].toUpperCase()}</Typography> */}
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px', textAlign: 'center' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">PARKING</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">STATUS</Typography>
                                <Typography sx={{ fontSize: { sm: '14px', xs: '14px' }, fontWeight: 500 }} color={'#707070'} variant="body1">{lng[language]['plan'].toUpperCase()}</Typography>
                            </Box>

                        </Box>
                    </Box>
                </Box>
                {
                    !dataSet.loading ?
                        <>
                            <Box>
                                <>
                                    {
                                        visibleAps.length !== 0 ?
                                            <Box>
                                                {
                                                    visibleAps.map((data, index) => (
                                                        <Box
                                                            onClick={() =>
                                                                isMobile ?
                                                                handleImageModal(data.apartmentId, `${language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName} ${language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName} ${data.objectName}`, data.orientationName, data.apartmentId)
                                                                :
                                                                handleNewApsShow(data.apartmentId)
                                                            }
                                                            key={index}
                                                            sx={[{
                                                                backgroundColor: data?.sold ? '#FFDDDD' : '', cursor: 'pointer', width: { md: '100%', xs: '100%' }, display: 'grid', marginX: 'auto', gridGap: '20px', color: '#707070', alignItems: 'center', justifyItems: 'center', gridTemplateColumns: { sm: '1fr 1fr 1fr 1fr 1fr 1fr', xs: '1fr 1fr 1fr 1fr 1fr 1fr' }, rowGap: { sm: '30px', xs: '5px' }, borderTop: 'solid 1px #d4d4d4', borderBottom: dataSet?.data?.length === index + 1 ? 'solid 1px #707070' : '', transition: '.2s all',
                                                            },
                                                            data.status === 'free' ? { '&:hover': { backgroundColor: '#d2f8d2' } } : {},
                                                            data.status === 'sold' ? { '&:hover': { backgroundColor: '#FFF0F0' } } : {},
                                                            data.status === 'notForSale' ? { '&:hover': { backgroundColor: '#FFFDE8' } } : {},
                                                            data.status === 'reserved' ? { '&:hover': { backgroundColor: '#FFFDE8' } } : {},
                                                            data.status === 'salesAgreed' ? { '&:hover': { backgroundColor: '#E8ECFC' } } : {},
                                                            data.status === 'approved' ? { '&:hover': { backgroundColor: '#cecece' } } : {},
                                                            ]}
                                                            onMouseEnter={() => { setImage(data.apartmentId); setOrientation(lng[language][data.orientationName]); setName(`${language === 'eng' ? data.apartmentName.replace('Apartamenti', 'Apartment') : data.apartmentName} ${language === 'eng' ? data.buildingName.replace('Godina', 'Building') : data.buildingName} ${data.objectName}`) }}
                                                        >
                                                            {/* <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '14px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>+{data.floorNo}</Typography>
                                                            </Box> */}
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.typology}</Typography>
                                                            </Box>

                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {data.surfaceTerrace ? `${data.surfaceTerrace} M²` : '0 M²'}
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>{data.surfaceGrossWithTerrace ? data.surfaceGrossWithTerrace : 0}M²</Typography>
                                                            </Box>
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '11px' }, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {
                                                                        parseInt(data.apartmentId) === 1718
                                                                            ?
                                                                            '2 Parkings'
                                                                            :
                                                                            '1 Parking'
                                                                    }
                                                                </Typography>
                                                            </Box>

                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={[{
                                                                    width: { sm: 1, xs: '47px' },
                                                                    fontSize: { sm: '13.5px', xs: language === 'alb' ? '7px' : '8px' },
                                                                    marginX: 'auto',
                                                                    overflow: 'unset',
                                                                    textOverflow: 'clip',
                                                                    padding: '10px 0',
                                                                    fontWeight: { md: 'inherit', xs: 'bold' }
                                                                },
                                                                    // data.status === 'free' ? {  color: {md : 'unset',xs: '#d2f8d2'}  } : {},
                                                                    // data.status === 'sold' ? {  color: {md : 'unset',xs:'#FFF0F0'}  } : {},
                                                                    // data.status === 'notForSale' ? {  color: {md : 'unset',xs:'#FFFDE8'}  } : {},
                                                                    // data.status === 'reserved' ? {  color: {md : 'unset',xs:'#FFFDE8'}  } : {},
                                                                    // data.status === 'salesAgreed' ? {  color    : {md : 'unset',xs:'#E8ECFC'}  } : {},

                                                                ]}>
                                                                    {
                                                                        data.status === 'free' && lng[language]['free'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'sold' && lng[language]['sold'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'notForSale' && lng[language]['reserved'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'reserved' && lng[language]['reserved'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'salesAgreed' && lng[language]['salesAgreed'].toUpperCase()
                                                                    }
                                                                    {
                                                                        data.status === 'approved' && lng[language]['approved'].toUpperCase()
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                            <Box sx={{ width: { sm: 1, xs: '47px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <Typography textAlign={'center'} variant="subtitle1" component={'p'} sx={[{ width: { sm: 1, xs: '47px' }, fontSize: { sm: '13.5px', xs: '9px' }, marginX: 'auto', overflow: 'unset', textOverflow: 'clip', padding: '10px 0', color: '#333132', fontWeight: 800 }]}>
                                                                    {lng[language]['viewPlan'].toUpperCase()}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    ))
                                                }
                                            </Box>
                                            :
                                            <Typography sx={{ gridColumnStart: 1, gridColumnEnd: 7, fontWeight: 600 }} textAlign={'center'} variant='h4' color={'#11294D'}>NO DATA</Typography>
                                    }
                                </>

                                {
                                    otherLoader &&
                                    <Box sx={{ display: 'grid', gridTemplateRows: '1fr', rowGap: '5px' }}>
                                        {
                                            AvaLoader.map((data, index) => (
                                                <SkeletonComp
                                                    key={index}
                                                    height={data.heigh}
                                                    width={data.width}
                                                    variant={data.variant}
                                                />
                                            ))
                                        }
                                    </Box>
                                }
                                {
                                    (count > parseInt(visibleAps.length) || count === parseInt(visibleAps.length)) &&
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '40px' }}>
                                        {
                                            dataSet.loading || otherLoader ?
                                                <CircularProgress sx={{ width: '15px', height: '15px', color: '#002856' }} />
                                                :
                                                <Button variant='contained' onClick={() => loadMoreData(15)} sx={{ borderRadius: 0, background: '#002856', boxShadow: 'none', border: 'solid 1px #002856', '&:hover': { background: '#FFF', color: '#002856' } }}>
                                                    {lng[language]['loadMore']}
                                                </Button>
                                        }
                                    </Box>
                                }
                                {
                                    imageModal && isMobile &&
                                    <ModalComp>
                                        <Box sx={{ background: '#FFF', display: 'flex', flexDirection: 'column', width: '80%', height: 'auto', alignItems: 'center', justifyItems: 'center' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '90%', marginX: 'auto', cursor: 'pointer', marginY: '20px' }} onClick={() => freeState()}>
                                                <CloseIcon />
                                            </Box>

                                            <Box marginBottom={'20px'} width={'90%'}>
                                                {(image !== 'Default' && !imageLoading) &&
                                                    <Box sx={{ color: '#11294D' }}>
                                                        <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{name}</Typography>
                                                        <Box component={'span'} sx={{ width: '100%', marginX: '0', height: '1px', backgroundColor: '#d4d4d4', display: 'block', marginY: '10px' }} />
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginRight: 'auto' }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '30px', xs: '20px' } }} />
                                                                <Typography variant="subtitle1" marginLeft={'10px'} sx={{ fontSize: '18px' }}>{lng[language]['orientation']}</Typography>
                                                            </Box>
                                                            <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{orientation}</Typography>
                                                        </Box>
                                                    </Box>
                                                }
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginY: '20px' }}>
                                                <Box component={'img'}  loading="lazy" src={`https://fundway.com/images/2024/${image}.png`} sx={{ width: '90%', objectFit: 'contain' }} />
                                            </Box>
                                            <Box marginY={'20px'} sx={{ width: '90%' }}>
                                                <Button onClick={() => handleRidirect(data.typology, id)} sx={{ backgroundColor: '#11294D', boxShadow: 'none', color: '#FFF', borderRadius: 0, border: 'solid 1px #11294D', '&:hover': { backgroundColor: '#FFF', color: '#11294D' }, width: '100%' }}>
                                                    {lng[language]['viewPlan']}
                                                </Button>
                                            </Box>
                                        </Box>

                                    </ModalComp>
                                }

                            </Box>
                        </>
                        :
                        <Box sx={{
                            display: 'grid',
                            gridTemplateRows: '1fr',
                            rowGap: '5px'
                        }}>
                            {
                                AvaLoader.map((data, index) => (
                                    <SkeletonComp
                                        key={index}
                                        height={data.heigh}
                                        width={data.width}
                                        variant={data.variant}
                                    />
                                ))
                            }
                        </Box>
                }

            </Box>

            {
                newApartment && !loading && showNewAps && !isMobile ?
                    <Box sx={{ position: 'fixed', top: 0, right: 0, width: '50%', height: "100%", backgroundColor: '#FFF', borderLeft: 'solid 1px #000', overflowY: 'scroll', zIndex: 980 }}>
                         
                        <Box color={'#002856'} sx={{ display: 'flex', justifyContent: 'space-between', width: '80%', marginX: 'auto', alignItems: 'center'}}>
                            {
                                newApartment.typology === 'DUPLEX' ?
                                    <Typography variant="h3" sx={{ fontWeight: 'light', fontSize: '30px' }} textAlign={'center'} marginTop={5} marginBottom={2}>
                                        {
                                            newApartment.apartmentId === '1650'
                                                ?
                                                language === 'alb' ? 'DUPLEX 1 Building 1 B2'.replace('Building', 'Godina') : 'DUPLEX 1 Building 1 B2'
                                                :
                                                ''
                                        }
                                        {
                                            newApartment.apartmentId === '1651'
                                                ?
                                                language === 'alb' ? 'DUPLEX 2 Building 2 B2'.replace('Building', 'Godina') : 'DUPLEX 2 Building 2 B2'
                                                :
                                                ''
                                        }
                                        {
                                            newApartment.apartmentId === '1652'
                                                ?
                                                language === 'alb' ? 'DUPLEX 3 Building 3 B2'.replace('Building', 'Godina') : 'DUPLEX 3 Building 3 B2'
                                                :
                                                ''
                                        }
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={{ fontSize: '30px', fontWeight: 'light' }} textAlign={'center'} marginTop={5} marginBottom={2}>{language === 'eng' ? newApartment.apartmentName.replace('Apartamenti', 'Property') : newApartment.apartmentName} {language === 'eng' ? newApartment.buildingName.replace('Godina', 'Building') : newApartment.buildingName} {newApartment.objectName}</Typography>
                            }
                            <Box onClick={() => setNewShowAps(false)} sx={{ marginLeft: 'auto', width: 'min-content', marginRight: '10px', marginTop: '10px', cursor: 'pointer' }}>
                            <CloseIcon />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                marginTop: '50px',
                                width: {
                                    md: '80%',
                                    sm: '80%',
                                    xs: '90%'
                                },
                                rowGap: '10px',
                                columnGap: '30px',
                                marginX: 'auto',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                            }}
                        >
                            <Box sx={{ display: 'grid', gridTemplateRows: '40px 40px 40px 40px ', rowGap: '10px', }}>
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/stair.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['flootTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>+{newApartment.floorNo}</Typography>
                                </Box>

                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/double-bed.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['bedsTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{newApartment.typology}</Typography>
                                </Box>


                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/icons/eye.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['orientation'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{lng[language][newApartment.orientationName]}</Typography>
                                </Box>

                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/terrace.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['terraceTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{newApartment.surfaceTerrace ? newApartment.surfaceTerrace : '0'} M²</Typography>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'grid', gridTemplateRows: '40px 40px 40px 40px ', rowGap: '10px', }}>
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'max-content'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['apartmentSize'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{newApartment.surfaceGrossWithoutTerrace} M²</Typography>
                                </Box>
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'max-content'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/scalability.svg' sx={{ width: { sm: '30px', xs: '30px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>{lng[language]['areaTxt'].toUpperCase()}</Typography>
                                    </Box>
                                    <Typography fontWeight={500}>{newApartment.surfaceGrossWithTerrace} M²</Typography>
                                </Box>
                                <Box sx={info}>
                                    <Box display={'flex'} alignItems={'center'} width={'250px'}>
                                        <Box component={'img'}  loading="lazy" src='https://fundway.com/images/2024/parking.svg' sx={{ width: { sm: '25px', xs: '24px' }, height: '100%', objectFit: 'contain' }} />
                                        <Typography marginLeft={'10px'} fontWeight={500}>PARKING</Typography>
                                    </Box>
                                    {newApartment.typology === 'DUPLEX' ? <Typography fontWeight={500}> {newApartment.apartmentId === '1650' ? language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2'] : ''} {newApartment.apartmentId === '1651' ? language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2'] : ''} {newApartment.apartmentId === '1652' ? language === 'alb' ? lng[language]['parging2'] : lng[language]['parging2'] : ''} </Typography> : <Typography fontWeight={500}>{lng[language]['parking1']}</Typography>}
                                </Box>
                                <Box></Box>
                            </Box>
                        </Box>

                        <Box sx={{ marginY: '30px', width: { md: '80%', sm: '80%', xs: '90%' }, marginX: 'auto', height: { md: 500, xs: 'auto' } }}>
                            <Box component={'img'}  loading="lazy" src={`https://www.fundway.com/images/apartments/${newApartment?.imageName}`} sx={{ width: 1, height: 1, objectFit: 'contain' }} />
                        </Box>
                        {
                            newApartment.status === 'free' &&
                            <Box sx={{ display: 'grid', justifyItems: 'center', marginY: 5, gridTemplateColumns: '1fr 1fr', width: 'fit-content', alignContent: 'center', marginX: 'auto', columnGap: '20px' }}>

                                <Button onClick={() => setOpenModal(true)} sx={{ width: '100%', color: '#c3c3c3', boxShadow: 'none', border: 'solid 1px #c3c3c3', borderRadius: 0, padding: '15px 15px' }}>
                                    {lng[language]['downloadPlan']}
                                </Button>
                                <Button sx={{ width: '100%', color: '#FFF', background: '#002856', boxShadow: 'none', border: 'solid 1px #002856', borderRadius: 0, padding: '15px 15px', '&:hover': { color: '#002856', background: '#FFF' } }} onClick={() => { trackCustomEvent('ViewContent', { Apartment_id: newApartment.apartmentId, Date: new Date() }); navigate(`../reserve/${newApartment.apartmentId}`); }}>
                                    {lng[language]['takeOffer']}
                                </Button>
                            </Box>
                        }


                    </Box>
                    :
                    <Loader state={loading} />
            }

            {
                openModal &&
                <Box sx={{ position: 'fixed', top: 0, left: 0, background: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) )', width: '100%', height: '100%', zIndex: 990, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ background: '#FFF', width: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
                        <Box onClick={() => setOpenModal(false)} sx={{ marginLeft: 'auto', width: 'min-content', marginRight: '10px', marginTop: '10px' }}>
                            <CloseIcon />
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr auto', gridGap: '20px' }}>

                            <Box>
                                <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['name']}</InputLabel>
                                <TextField
                                    fullWidth
                                    sx={inputStyle}
                                    // placeholder='Shruani emrin e plotë'
                                    value={formData.firstname}
                                    error={formDataErrors.firstnameError}
                                    onChange={(e) => inputFunc.firstnameInput(e)}
                                />
                            </Box>

                            <Box>
                                <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['lastName']}</InputLabel>
                                <TextField
                                    fullWidth
                                    sx={inputStyle}
                                    // placeholder='Shruani emrin e plotë'
                                    value={formData.lastname}
                                    error={formDataErrors.lastnameError}
                                    onChange={(e) => inputFunc.lastnameInput(e)}
                                />
                            </Box>


                            <Box>
                                <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['countty2']}</InputLabel>
                                <FormControl sx={[{ '.MuiInputBase-root': { borderRadius: 0, '::before': { content: 'none' }, '::after': { content: 'none' }, border: 'solid 1px', borderColor: formDataErrors.countryError ? '#d32f2f !important' : '#c4c4c4', '&:hover': { borderColor: '#000' } }, '.MuiNativeSelect-select': { padding: '15px !important' }, width: '100%' }]}>
                                    <NativeSelect onChange={(e) => inputFunc.countryInput(e)} value={formData.countryId}>
                                        <option value="">{lng[language]['selectCountry']}</option>
                                        {
                                            Countries.map((data, index) => (
                                                <option key={index} data-countryname={data.country} value={Number(data.countryId)}>{data.country} {data.flag}</option>
                                            ))
                                        }
                                    </NativeSelect>
                                </FormControl>
                            </Box>

                            <Box>
                                <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['email']}</InputLabel>
                                <TextField
                                    fullWidth
                                    sx={inputStyle}
                                    value={formData.email}
                                    error={formDataErrors.emailError}
                                    onChange={(e) => inputFunc.emailInput(e)}
                                />
                            </Box>

                            <Box>
                                <InputLabel sx={{ fontWeight: '600', marginBottom: '10px', color: 'gray' }}>{lng[language]['phone']}</InputLabel>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControl sx={{ '.MuiInputBase-root': { borderRadius: 0 }, width: '132px', '.MuiSelect-select': { paddingY: '16.5px !important', paddingX: '5px' }, background: '#FFF' }}>
                                        <Select onChange={(e) => inputFunc.prefixInput(e)} value={formData.prefix} error={formDataErrors.prefixError}>
                                            {
                                                Countries.map((data, index) => (
                                                    <MenuItem key={index} value={data.phone_code}>{data.phone_code} {data.flag}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        type='tel'
                                        fullWidth
                                        sx={[{ marginLeft: '10px' }, inputStyle]}
                                        value={formData.tel}
                                        error={formDataErrors.telError}
                                        onChange={(e) => inputFunc.telInput(e)}
                                    />
                                </Box>
                            </Box>

                            <Button
                                onClick={(e) => !loadingButton ? handleSubmit(e) : null}
                                sx={{
                                    boxShadow: 'none',
                                    width: !loadingButton ? 'max-content' : '140px',
                                    backgroundColor: '#002856',
                                    padding: 1.6,
                                    borderRadius: 0,
                                    color: '#FFF',
                                    justifySelf: 'center',
                                    border: 'solid 1px #002856',
                                    marginY: '20px',
                                    '&:hover': {
                                        color: '#002856',
                                        background: '#FFF'
                                    }
                                }}
                                disabled={loadingButton ? true : false}
                            >
                                {
                                    !loadingButton ?
                                        lng[language]['downloadPlan']
                                        :
                                        <CircularProgress sx={{ width: '25px !important', height: '25px !important', color: '#FFF' }} />
                                }
                            </Button>
                        </Box>
                    </Box>


                </Box>
            }

        </Box>
    )
}