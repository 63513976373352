import { api } from '../../api/config';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import Loader from '../../components/loader/Loader';
import Rows from '../../layout/builder/Rows';
export default function BlogContent() {
    const { id } = useParams();
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const language = useSelector((state) => state.language.language);

    function decodeHtmlEntities(str) {
        var doc = new DOMParser().parseFromString(str, 'text/html');
        return doc.documentElement.textContent;
    }

    const getData = () => {
        setLoading(true);
        api.post('/admin/getData', { lang: language, conId: id })
            .then(res => {
                if (res.data.success === true) {
                    const decodedData = decodeHtmlEntities(res.data.data);
                    const jsonData = JSON.parse(decodedData);
                    setBlog(jsonData);
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [id, language]);

    return (
        !loading ?
            blog.map((row) => (
                <Rows
                    key={row.id}
                    id={row.id}
                    row={row}
                />
            ))
            :
            <Loader state={loading} />

    )
}